<template>
  <el-card v-if="user.name">
    <el-tabs v-model="activeActivity">
      <el-tab-pane
        v-loading="updating"
        :label="this.$t('user.account')"
        name="first"
      >
        <el-form-item :label="this.$t('user.name')">
          <el-input v-model="user.name" :disabled="user.role === 'Admin'" />
        </el-form-item>
        <el-form-item :label="this.$t('user.email')">
          <el-input v-model="user.email" :disabled="user.role === 'Admin'" />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            :disabled="user.role === 'Admin'"
            @click="checkLoginForm = true"
          >
            {{ this.$t("general.save") }}
          </el-button>
        </el-form-item>
      </el-tab-pane>

      <el-tab-pane
        v-loading="updating"
        :label="this.$t('user.password')"
        name="second"
      >
        <div class="block">
          <el-form
            ref="userForm"
            :rules="rules"
            :model="user"
            label-position="left"
            label-width="150px"
          >
            <el-form-item :label="this.$t('user.password')" prop="password">
              <el-input v-model="user.password" show-password />
            </el-form-item>

            <el-form-item
              :label="this.$t('user.c_password')"
              prop="c_password"
            >
              <el-input v-model="user.c_password" show-password />
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                :disabled="user.role === 'Admin'"
                @click="showLoginCheck"
              >
                {{ this.$t("general.save") }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
    </el-tabs>

    <el-dialog
      :title="this.$t('user.enterPassword')"
      :visible.sync="checkLoginForm"
    >
      <el-form-item :label="this.$t('user.password')" prop="password">
        <el-input v-model="checkLogin.currentpassword" show-password />
      </el-form-item>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkLoginForm = false">
          {{ this.$t("general.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="checkLoginAndSubmit(checkLogin.currentpassword)"
        >
          {{ this.$t("general.confirm") }}
        </el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import Resource from '@/api/resource';
const userResource = new Resource('users');
const checkPasswordResource = new Resource('checkpassword');

export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: [],
        };
      },
    },
  },
  data() {
    var validateConfirmPassword = (rule, value, callback) => {
      if (value !== this.user.password) {
        callback(new Error(this.$t('user.PasswordsInconform')));
      } else {
        callback();
      }
    };

    return {
      checkLoginForm: false,
      rules: {
        password: [
          {
            required: true,
            message: this.$t('user.PasswordRequired'),
            trigger: 'blur',
          },
        ],
        c_password: [
          { validator: validateConfirmPassword, trigger: 'blur' },
        ],
      },

      activeActivity: 'first',
      updating: false,
      islogedin: '',

      checkLogin: {
        currentpassword: '',
      },
    };
  },
  methods: {
    showLoginCheck() {
      this.$refs['userForm'].validate((valid) => {
        if (valid) {
          this.checkLogin.currentpassword = '';
          this.checkLoginForm = true;
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
        }
      });
    },

    async checkLoginAndSubmit() {
      const data = await checkPasswordResource.store(this.checkLogin);
      this.islogedin = data.status;
      if (this.islogedin) {
        this.checkLoginForm = false;
        this.onSubmit();
      }
    },

    onSubmit() {
      this.updating = true;
      userResource
        .update(this.user.id, this.user)
        .then((response) => {
          this.updating = false;
          this.checkLogin.currentpassword = '';
          this.$message({
            message: this.$t('user.profileUpdated'),
            type: 'success',
            duration: 5 * 1000,
          });
          this.createActivity(
            'Profile',
            'Vous avez modifier les informations de votre profil'
          );
        })
        .catch((error) => {
          console.log(error);
          this.updating = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-activity {
  .user-block {
    .username,
    .description {
      display: block;
      margin-left: 50px;
      padding: 2px 0;
    }
    img {
      width: 40px;
      height: 40px;
      float: left;
    }
    :after {
      clear: both;
    }
    .img-circle {
      border-radius: 50%;
      border: 2px solid #d2d6de;
      padding: 2px;
    }
    span {
      font-weight: 500;
      font-size: 12px;
    }
  }
  .post {
    font-size: 14px;
    border-bottom: 1px solid #d2d6de;
    margin-bottom: 15px;
    padding-bottom: 15px;
    color: #666;
    .image {
      width: 100%;
    }
    .user-images {
      padding-top: 20px;
    }
  }
  .list-inline {
    padding-left: 0;
    margin-left: -5px;
    list-style: none;
    li {
      display: inline-block;
      padding-right: 5px;
      padding-left: 5px;
      font-size: 13px;
    }
    .link-black {
      &:hover,
      &:focus {
        color: #999;
      }
    }
  }
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}
</style>
