<template>
  <el-card v-if="user.name">
    <div slot="header" class="clearfix">
      <span>{{ user.name }}</span>
    </div>
    <div class="user-profile">
      <div class="components-container">
        <div class="user-avatar box-center">
          <pan-thumb :image="image" />
        </div>

        <div class="box-center">
          <div class="user-name text-center">
            {{ user.name }}
          </div>
          <div class="user-role text-center text-muted">
            {{ getRole() }}
          </div>
        </div>

        <div class="user-follow">
          <el-button
            type="primary"
            icon="upload"
            style="width: 100%"
            @click="imagecropperShow = true"
          >
            {{ $t("user.changeAvatar") }}
          </el-button>
        </div>

        <image-cropper
          v-show="imagecropperShow"
          :key="imagecropperKey"
          :width="300"
          :height="300"
          url="/saveprofilepic"
          lang-type="fr"
          @close="close"
          @crop-upload-success="cropSuccess"
        />
      </div>
    </div>
  </el-card>
</template>

<script>
import PanThumb from '@/components/PanThumb';
import ImageCropper from '@/components/ImageCropper';
import waves from '@/directive/waves'; // Waves directive
import { mapGetters } from 'vuex';

export default {
  directives: { waves },
  components: { ImageCropper, PanThumb },
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: '',
          avatar: '',
          roles: [],
        };
      },
    },
  },
  data() {
    return {
      imagecropperShow: false,
      imagecropperKey: 0,
      image: '',
      userAvatar: '',
    };
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name',
      'avatar',
      'device',
      'userId',
      'userSectionId',
    ]),
  },

  created() {
    this.image = this.avatar;
  },

  methods: {
    cropSuccess() {
      this.imagecropperShow = false;
      this.imagecropperKey = this.imagecropperKey + 1;
      this.$emit('changeAvatar', true);
    },

    close() {
      this.imagecropperShow = false;
    },

    getRole() {
      const roles = this.user.roles.map((value) =>
        this.$options.filters.uppercaseFirst(value)
      );
      return roles.join(' | ');
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile {
  .user-name {
    font-weight: bold;
  }
  .box-center {
    padding-top: 10px;
  }
  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }
  .box-social {
    padding-top: 30px;
    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }
  .user-follow {
    padding-top: 20px;
  }
}
</style>
