<template>
  <div class="app-container">
    <el-form v-if="user" :model="user">
      <el-row :gutter="20">
        <el-col :span="6">
          <user-card
            :user="user"
            @changeAvatar="getUser(userId); notifyChange()"
          />
        </el-col>
        <el-col :span="18">
          <user-activity :user="user" />
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import Resource from '@/api/resource';
import UserCard from './components/UserCard';
import UserActivity from './components/UserActivity';
import waves from '@/directive/waves'; // Waves directive
import { mapGetters } from 'vuex';
const userResource = new Resource('users');
export default {
  name: 'EditUser',
  directives: { waves },
  components: { UserCard, UserActivity },
  data() {
    return {
      user: {},
    };
  },

  computed: {
    ...mapGetters([
      'sidebar',
      'name',
      'avatar',
      'device',
      'userId',
      'userSectionId',
    ]),
  },
  watch: {
    '$route': 'getUser',
  },
  created() {
    // const id = this.$route.params && this.$route.params.id;
    this.getUser(this.userId);
  },
  methods: {

    /* updateProfile() {
      userResource.update(this.user.id, this.user).then(response => {
        this.$message({
          type: 'success',
          message: this.$t('general.updated'),
          duration: 5 * 1000,
        });
      }).catch(error => {
        console.log(error);
      }).finally(() => {
      });
    },*/
    notifyChange(){
      this.$message({
        message: this.$t('user.profileUpdated'),
        type: 'success',
        duration: 5 * 1000,
      });
    },
    async getUser(id) {
      const { data } = await userResource.get(id);
      this.user = data;
    },
  },
};
</script>
